import '../SotibOlish/sidebar.css'

export const SuperAdminIcon = () => (
        <svg width="18" height="20" className={'svgFillColorSubMenu'} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="user polygon">
                <path id="Vector" opacity="0.4" d="M6.92154 0.571247C8.2077 -0.190416 9.7923 -0.190416 11.0785 0.571247L15.9215 3.43932C17.2077 4.20099 18 5.6086 18 7.13192V12.8681C18 14.3914 17.2077 15.799 15.9215 16.5607L11.0785 19.4288C9.7923 20.1904 8.20769 20.1904 6.92154 19.4288L2.07846 16.5607C0.792305 15.799 0 14.3914 0 12.8681V7.13192C0 5.6086 0.792305 4.20099 2.07846 3.43932L6.92154 0.571247Z" fill="#28303F"/>
                <g id="Vector_2">
                    <path d="M6.92155 19.4286C8.20771 20.1903 9.79232 20.1903 11.0785 19.4286L15.301 16.928C14.0431 14.698 11.6924 13.1978 9.00001 13.1978C6.30764 13.1978 3.95688 14.698 2.69897 16.928L6.92155 19.4286Z" fill="#28303F"/>
                </g>
                <circle id="Ellipse 260" cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 6 11)" fill="#28303F"/>
            </g>
        </svg>
);
export const MainMenu = () => (
    <svg width="22" height="22" className={'svgColor'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3334 15.5833C18.3334 17.1021 17.1022 18.3333 15.5834 18.3333C14.0646 18.3333 12.8334 17.1021 12.8334 15.5833C12.8334 14.0646 14.0646 12.8333 15.5834 12.8333C17.1022 12.8333 18.3334 14.0646 18.3334 15.5833Z" fill="#1AA6E1"/>
        <g opacity="0.4">
            <path d="M12.8334 5.5C12.8334 4.48748 13.6542 3.66667 14.6667 3.66667H16.5C17.5125 3.66667 18.3334 4.48748 18.3334 5.5V7.33333C18.3334 8.34586 17.5125 9.16667 16.5 9.16667H14.6667C13.6542 9.16667 12.8334 8.34586 12.8334 7.33333V5.5Z" fill="#1AA6E1"/>
            <path d="M3.66669 5.5C3.66669 4.48748 4.4875 3.66667 5.50002 3.66667H7.33335C8.34588 3.66667 9.16669 4.48748 9.16669 5.5V7.33333C9.16669 8.34586 8.34588 9.16667 7.33335 9.16667H5.50002C4.4875 9.16667 3.66669 8.34586 3.66669 7.33333V5.5Z" fill="#1AA6E1"/>
            <path d="M3.66669 14.6667C3.66669 13.6541 4.4875 12.8333 5.50002 12.8333H7.33335C8.34588 12.8333 9.16669 13.6541 9.16669 14.6667V16.5C9.16669 17.5125 8.34588 18.3333 7.33335 18.3333H5.50002C4.4875 18.3333 3.66669 17.5125 3.66669 16.5V14.6667Z" fill="#1AA6E1"/>
        </g>
    </svg>

);

export const Kassa = () => (
    <svg className={'svgColorSubMenu'} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="money bag dollar">
            <path id="Rectangle 838" opacity="0.4" d="M9.91205 5.41667L6.08791 5.41667L4.80359 3.94355C3.54461 2.49949 5.01334 0.341064 6.85146 0.93403L7.70192 1.20838C7.89555 1.27085 8.10441 1.27085 8.29804 1.20838L9.1485 0.93403C10.9866 0.341065 12.4554 2.49949 11.1964 3.94355L9.91205 5.41667Z" fill="#B0B7C3"/>
            <path id="Rectangle 837" opacity="0.4" d="M2.21058 8.35949C2.92811 6.06337 5.0546 4.5 7.46022 4.5H8.53978C10.9454 4.5 13.0719 6.06337 13.7894 8.35949L14.9353 12.0262C16.042 15.5678 13.3961 19.1667 9.68562 19.1667H6.31438C2.60386 19.1667 -0.0420141 15.5678 1.06474 12.0262L2.21058 8.35949Z" fill="#B0B7C3"/>
            <path id="Vector (Stroke)" stroke-width="0.3"  fill-rule="evenodd" clip-rule="evenodd" d="M7.99996 7.02084C8.37965 7.02084 8.68746 7.32864 8.68746 7.70834V8.484C9.70131 8.74766 10.5208 9.58085 10.5208 10.6875C10.5208 11.0672 10.213 11.375 9.83329 11.375C9.4536 11.375 9.14579 11.0672 9.14579 10.6875C9.14579 10.2646 8.72189 9.77084 7.99996 9.77084C7.27803 9.77084 6.85413 10.2646 6.85413 10.6875C6.85413 11.1104 7.27803 11.6042 7.99996 11.6042C9.30307 11.6042 10.5208 12.5468 10.5208 13.8958C10.5208 15.0025 9.70131 15.8357 8.68746 16.0993V16.875C8.68746 17.2547 8.37965 17.5625 7.99996 17.5625C7.62026 17.5625 7.31246 17.2547 7.31246 16.875V16.0993C6.29861 15.8357 5.47913 15.0025 5.47913 13.8958C5.47913 13.5161 5.78693 13.2083 6.16663 13.2083C6.54632 13.2083 6.85413 13.5161 6.85413 13.8958C6.85413 14.3187 7.27803 14.8125 7.99996 14.8125C8.72189 14.8125 9.14579 14.3187 9.14579 13.8958C9.14579 13.4729 8.72189 12.9792 7.99996 12.9792C6.69685 12.9792 5.47913 12.0365 5.47913 10.6875C5.47913 9.58085 6.29861 8.74766 7.31246 8.484V7.70834C7.31246 7.32864 7.62026 7.02084 7.99996 7.02084Z" fill="#B0B7C3"/>
        </g>
    </svg>
);

export const UsersIcon = () => (
    <svg width="18" height="18" className={'svgFillColorSubMenu'} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="2 user">
            <ellipse id="Ellipse 296" opacity="0.4" cx="11.75" cy="5.33333" rx="2.75" ry="2.75" fill="#B0B7C3"/>
            <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M12.7721 15.3693C13.2888 14.8408 13.5834 14.2317 13.5834 13.5833C13.5834 12.0238 11.8795 10.6917 9.47754 10.1616C10.1702 10.0043 10.9396 9.91667 11.75 9.91667C14.7876 9.91667 17.25 11.1479 17.25 12.6667C17.25 14.0108 15.3213 15.1298 12.7721 15.3693Z" fill="#B0B7C3"/>
            <ellipse id="Ellipse 293" opacity="0.4" cx="7.16667" cy="13.5833" rx="6.41667" ry="3.66667" fill="#B0B7C3"/>
            <circle id="Ellipse 294" cx="7.16667" cy="4.41667" r="3.66667" fill="#B0B7C3"/>
        </g>
    </svg>

);
export const CustomerIcon = () => (
    <svg className={'svgColorSubMenu'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="briefcase">
            <path id="Rectangle 392" opacity="0.4" d="M0.833313 8.16667C0.833313 6.14162 2.47494 4.5 4.49998 4.5H15.5C17.525 4.5 19.1666 6.14162 19.1666 8.16667V15.5C19.1666 17.525 17.525 19.1667 15.5 19.1667H4.49998C2.47494 19.1667 0.833313 17.525 0.833313 15.5V8.16667Z" fill="#B0B7C3"/>
            <path id="Vector 998 (Stroke)" opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9.08331 1.52083C7.94423 1.52083 7.02081 2.44424 7.02081 3.58333V5.41666C7.02081 5.79636 6.71301 6.10416 6.33331 6.10416C5.95362 6.10416 5.64581 5.79636 5.64581 5.41666V3.58333C5.64581 1.68485 7.18483 0.145828 9.08331 0.145828H10.9166C12.8151 0.145828 14.3541 1.68485 14.3541 3.58333V5.41666C14.3541 5.79636 14.0463 6.10416 13.6666 6.10416C13.287 6.10416 12.9791 5.79636 12.9791 5.41666V3.58333C12.9791 2.44424 12.0557 1.52083 10.9166 1.52083H9.08331Z" fill="#B0B7C3"/>
            <path id="Vector" d="M15.5 4.5H4.49998C2.47494 4.5 0.833313 6.14162 0.833313 8.16667V9.08333C0.833313 9.08333 6.42017 12.75 9.99998 12.75C13.5798 12.75 19.1666 9.08333 19.1666 9.08333V8.16667C19.1666 6.14162 17.525 4.5 15.5 4.5Z" fill="#B0B7C3"/>
            <path id="Ellipse 708" d="M11.8333 12.75C11.8333 13.7625 11.0125 14.5833 9.99996 14.5833C8.98744 14.5833 8.16663 13.7625 8.16663 12.75C8.16663 11.7375 8.98744 10.9167 9.99996 10.9167C11.0125 10.9167 11.8333 11.7375 11.8333 12.75Z" fill="#B0B7C3"/>
        </g>
    </svg>
);
export const ProductIcon = () => (
    <svg className={'svgColorSubMenu'} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="package box">
            <path id="Vector" opacity="0.4" d="M15.0725 2.53223L10.4892 0.495193C9.5411 0.0738269 8.4589 0.0738276 7.51082 0.495193L2.92749 2.53223C1.60336 3.12073 0.75 4.43385 0.75 5.88287V12.1171C0.75 13.5662 1.60336 14.8793 2.92749 15.4678L7.51083 17.5048C8.4589 17.9262 9.5411 17.9262 10.4892 17.5048L15.0725 15.4678C16.3966 14.8793 17.25 13.5662 17.25 12.1171V5.88287C17.25 4.43385 16.3966 3.12073 15.0725 2.53223Z" fill="#B0B7C3"/>
            <path id="Vector_2" d="M15.0726 2.53238L10.4893 0.495345C9.54118 0.0739795 8.45897 0.0739802 7.5109 0.495346L2.92757 2.53238C2.32614 2.79968 1.82183 3.21647 1.4505 3.72758L9.00008 7.16683L16.5496 3.72758C16.1783 3.21647 15.674 2.79968 15.0726 2.53238Z" fill="#B0B7C3"/>
            <g id="Vector_3">
                <path d="M9 4.64584C9.3797 4.64584 9.6875 4.95365 9.6875 5.33334L9.6875 17.7558C9.23334 17.8425 8.76666 17.8425 8.3125 17.7558V5.33334C8.3125 4.95365 8.6203 4.64584 9 4.64584Z" fill="#B0B7C3"/>
            </g>
            <g id="Vector_4">
                <path d="M13.5834 3.72916C13.9631 3.72916 14.2709 4.03696 14.2709 4.41666V15.8241L12.8959 16.4352V4.41666C12.8959 4.03696 13.2037 3.72916 13.5834 3.72916Z" fill="#B0B7C3"/>
            </g>
        </g>
    </svg>
);
export const PurchaseIcon = () => (
    <svg width="20" height="20" className={'svgFillColorSubMenu'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="cart">
            <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M1.52081 0.145844C1.14112 0.145844 0.833313 0.453648 0.833313 0.833344C0.833313 1.21304 1.14112 1.52084 1.52081 1.52084H3.35415C3.98697 1.52084 4.49998 2.03385 4.49998 2.66668H5.87498C5.87498 1.27446 4.74636 0.145844 3.35415 0.145844H1.52081Z" fill="#B0B7C3"/>
            <path id="Rectangle 1057" opacity="0.4" d="M4.5 2.66669H15.5C17.525 2.66669 19.1667 4.30831 19.1667 6.33335V10.9167C19.1667 12.9417 17.525 14.5834 15.5 14.5834H8.16667C6.14162 14.5834 4.5 12.9417 4.5 10.9167V2.66669Z" fill="#B0B7C3"/>
            <ellipse id="Ellipse 651" cx="7.70831" cy="17.7917" rx="1.375" ry="1.375" fill="#B0B7C3"/>
            <ellipse id="Ellipse 652" cx="15.9583" cy="17.7917" rx="1.375" ry="1.375" fill="#B0B7C3"/>
            <path id="Vector 1265 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M15.0361 6.73261C15.3218 6.98264 15.3508 7.41698 15.1007 7.70273L12.47 10.7093C11.9047 11.3554 10.931 11.4419 10.2606 10.9056L8.65386 9.62019C8.35737 9.383 8.3093 8.95036 8.54649 8.65386C8.78369 8.35737 9.21632 8.3093 9.51282 8.5465L11.1196 9.8319C11.2153 9.90851 11.3544 9.89615 11.4352 9.80386L14.0659 6.79729C14.316 6.51154 14.7503 6.48258 15.0361 6.73261Z" fill="#B0B7C3"/>
        </g>
    </svg>

);
export const TradeIcon = () => (
    <svg className={'svgColorSubMenu'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="store">
            <path id="Rectangle 753" opacity="0.4" d="M18.25 15.5V5.41669H1.75V15.5C1.75 17.5251 3.39162 19.1667 5.41667 19.1667H14.5833C16.6084 19.1667 18.25 17.5251 18.25 15.5Z" fill="#B0B7C3"/>
            <path id="Rectangle 754" d="M5.41663 15.5C5.41663 14.4875 6.23744 13.6667 7.24996 13.6667C8.26248 13.6667 9.08329 14.4875 9.08329 15.5V19.1667H5.41663V15.5Z" fill="#B0B7C3"/>
            <path id="Vector" d="M19.1666 6.56251V4.83334C19.1666 2.62421 17.3758 0.833344 15.1666 0.833344H4.83331C2.62418 0.833344 0.833313 2.6242 0.833313 4.83334V6.56251C0.833313 8.46099 2.20133 10 3.88887 10C5.57641 10 6.94442 8.46099 6.94442 6.56251C6.94442 8.46099 8.31244 10 9.99998 10C11.6875 10 13.0555 8.46099 13.0555 6.56251C13.0555 8.46099 14.4236 10 16.1111 10C17.7986 10 19.1666 8.46099 19.1666 6.56251Z" fill="#B0B7C3"/>
            <path id="Rectangle 755" d="M11.8333 12.8333C11.8333 12.2811 12.281 11.8333 12.8333 11.8333H14.4999C15.0522 11.8333 15.4999 12.2811 15.4999 12.8333V13.5833C15.4999 14.1356 15.0522 14.5833 14.4999 14.5833H12.8333C12.281 14.5833 11.8333 14.1356 11.8333 13.5833V12.8333Z" fill="#B0B7C3"/>
        </g>
    </svg>
);

export const OutlayIcon = () => (
    <svg  className={'svgColorSubMenu'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="wallet arrow up">
            <path id="Rectangle 832" opacity="0.4" d="M19.1666 4.5H4.49996C2.47492 4.5 0.833294 6.14162 0.833294 8.16667V15.5C0.833294 17.525 2.47492 19.1667 4.49996 19.1667H15.5C17.525 19.1667 19.1666 17.525 19.1666 15.5V4.5Z" fill="#B0B7C3"/>
            <path id="Rectangle 833" d="M19.1666 4.50001C19.1666 2.47497 17.525 0.833344 15.5 0.833344H9.99998C7.97494 0.833344 6.33331 2.47497 6.33331 4.50001H19.1666Z" fill="#B0B7C3"/>
            <path id="Rectangle 834" d="M0.833313 10L0.833313 13.6667L4.49998 13.6667C5.5125 13.6667 6.33331 12.8459 6.33331 11.8333C6.33331 10.8208 5.5125 10 4.49998 10L0.833313 10Z" fill="#B0B7C3"/>
            <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M16.6458 14.9468L15.9861 15.6065C15.7176 15.8749 15.2823 15.8749 15.0138 15.6065C14.7453 15.338 14.7453 14.9027 15.0138 14.6342L16.199 13.449C16.8254 12.8226 17.8411 12.8226 18.4676 13.449L19.6528 14.6342C19.9212 14.9027 19.9212 15.338 19.6528 15.6065C19.3843 15.8749 18.949 15.8749 18.6805 15.6065L18.0208 14.9468V19.1667C18.0208 19.5464 17.713 19.8542 17.3333 19.8542C16.9536 19.8542 16.6458 19.5464 16.6458 19.1667V14.9468Z" fill="#B0B7C3"/>
        </g>
    </svg>
);

export const ReportIcon = () => (
    <svg className={'svgColorSubMenu'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="pie chart">
            <path id="Ellipse 719" opacity="0.4" d="M17.3333 10.9166C17.3333 15.473 13.6397 19.1666 9.08331 19.1666C4.52696 19.1666 0.833313 15.473 0.833313 10.9166C0.833313 6.36028 4.52696 2.66663 9.08331 2.66663C13.6397 2.66663 17.3333 6.36028 17.3333 10.9166Z" fill="#B0B7C3"/>
            <path id="Ellipse 406" d="M18.9512 8.01503C18.1853 4.54585 15.4541 1.81465 11.985 1.0488C10.9064 0.810691 10 1.72874 10 2.83331V7.99998C10 9.10455 10.8954 9.99998 12 9.99998H17.1667C18.2712 9.99998 19.1893 9.09363 18.9512 8.01503Z" fill="#B0B7C3"/>
        </g>
    </svg>
);

export const SettingIcon = () => (
    <svg className={'svgFillColorSubMenu'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="setting">
            <path id="Vector" opacity="0.4" d="M9.875 0.75H8.125C7.15849 0.75 6.37499 1.48873 6.37499 2.4C6.37499 3.44281 5.25924 4.1058 4.34333 3.60722L4.25031 3.55658C3.41329 3.10095 2.343 3.37134 1.85975 4.16052L0.98475 5.58946C0.501499 6.37865 0.788282 7.38777 1.6253 7.84341C2.54163 8.34222 2.54163 9.65778 1.6253 10.1566C0.78828 10.6122 0.501498 11.6214 0.984749 12.4105L1.85975 13.8395C2.343 14.6287 3.41329 14.8991 4.25031 14.4434L4.34333 14.3928C5.25923 13.8942 6.37499 14.5572 6.37499 15.6C6.37499 16.5113 7.15849 17.25 8.125 17.25H9.875C10.8415 17.25 11.625 16.5113 11.625 15.6C11.625 14.5572 12.7408 13.8942 13.6567 14.3928L13.7497 14.4434C14.5867 14.899 15.657 14.6286 16.1402 13.8395L17.0153 12.4105C17.4985 11.6213 17.2117 10.6122 16.3747 10.1566C15.4584 9.65777 15.4584 8.34223 16.3747 7.84342C17.2117 7.38778 17.4985 6.37866 17.0153 5.58948L16.1402 4.16054C15.657 3.37135 14.5867 3.10096 13.7497 3.55659L13.6567 3.60723C12.7408 4.10581 11.625 3.44282 11.625 2.4C11.625 1.48873 10.8415 0.75 9.875 0.75Z" fill="#B0B7C3"/>
            <circle id="Ellipse 91" cx="9" cy="9" r="2.75" fill="#B0B7C3"/>
        </g>
    </svg>
);

export const PersonIcon = () => (
    <svg className={'profile-items-icon'} viewBox="-0.5 0 64 64" version="1.1">
        <g id="Page-1" stroke="none"  fill="none"  >
            <path d="M41.6,44.3 C39,43 35.6,40.6 35.6,38.5 C35.6,37.4 36,36.7 36.4,36.5 C41.4,33.7 43.1,24.5 43.4,24.5 C45.1,24.5 46.3,20.2 46.3,17.4 C46.3,15.1 45.6,15.2 44.4,14.5 L44.4,14.2 C44.4,6.7 38.5,1 31.1,1 C23.8,1 17.5,7 17.5,14.4 L17.5,14.7 C16.3,15.4 15.8,15.8 15.8,18 C15.8,20.9 16.8,24.9 18.5,24.9 C18.8,24.9 21,33.7 25.8,36.7 C26.1,36.9 26.6,37.4 26.6,38.4 C26.6,40.8 23.4,42.9 20.7,44.3 C17.4,46 1,47.4 1,63 L61,63 C61,47.4 45.7,46.4 41.6,44.3 L41.6,44.3 Z" id="Person" stroke="#6B6C6E" stroke-width="3.5" >

            </path>
        </g>
    </svg>
);

export const EditIcon = () => (
    <svg className={'profile-items-icon'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export const LogOutIcon = () => (
    <svg className={'profile-items-icon'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Iconly/Curved/Logout">
            <g id="Logout">
                <path id="Stroke 1" d="M21.791 12.1208H9.75" stroke="#130F26" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Stroke 3" d="M18.8643 9.20483L21.7923 12.1208L18.8643 15.0368" stroke="#130F26" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Stroke 4" d="M16.3597 7.63C16.0297 4.05 14.6897 2.75 9.35974 2.75C2.25874 2.75 2.25874 5.06 2.25874 12C2.25874 18.94 2.25874 21.25 9.35974 21.25C14.6897 21.25 16.0297 19.95 16.3597 16.37" stroke="#130F26" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </g>
    </svg>
);

export const BurgerIcon = () => (
    <svg width="12" height="10" className={'svgColorSubMenu'}  viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="menu-line-horizontal">
            <path id="combo shape" fill-rule="evenodd" clip-rule="evenodd" d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H11C11.4142 0.25 11.75 0.585786 11.75 1C11.75 1.41421 11.4142 1.75 11 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM0.25 5C0.25 4.58579 0.585786 4.25 1 4.25H11C11.4142 4.25 11.75 4.58579 11.75 5C11.75 5.41421 11.4142 5.75 11 5.75H1C0.585786 5.75 0.25 5.41421 0.25 5ZM1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H11C11.4142 9.75 11.75 9.41421 11.75 9C11.75 8.58579 11.4142 8.25 11 8.25H1Z" fill="#28303F"/>
        </g>
    </svg>
);



